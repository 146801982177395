import React from 'react'
import { Menu } from '../../components';
import { BsCaretDown } from 'react-icons/bs'
import './Hero.css';
import Modal from 'react-modal';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgb(57, 57, 57, 0.95)',
    borderRadius: '1em',
    border: '0px solid white',
    color: 'white'
  },  
  close: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  overlay: { position: 'static' }
};
Modal.setAppElement('body');

const Hero = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = 'white';
  }

  function closeModal() {
    setIsOpen(false);
  }
  function handleSubmit() {
    const emailInput = document.getElementById("emailInput");
    const nameInput = document.getElementById("nameInput");
    const emailAddress = emailInput.value;
    const name = nameInput.value;

    if (emailAddress) {
      const recipientEmail = "support@legacyone.world"; // Change this to your desired recipient email address
      const subject = "LegacyOneBattleverse Beta Signup";
      const mailtoLink = `mailto:${recipientEmail}?subject=${subject}&body=Name: ${name} Email: ${emailAddress} `;
      window.location.href = mailtoLink;
      closeModal(); // Close the modal after submitting
    } else {
      alert("Please enter a valid email address.");
    }
  }


  return (
    <header className="bg-banner" id="main">
      <Menu />
      <div className="hero" id="hero">
        <h1 className="heading">Welcome to Legacy One: Battleverse!</h1>
        <h4 className="heading-subtitle">Experience battle like never before</h4>
        <a className="btn btn-custom"href="https://game.legacyone.io/" target="_blank" role="button">Join the Battleverse today</a>
        <a href="#about" className="scrolldown">
          <span>Learn more</span>
          <BsCaretDown />

        </a>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Be among the first to join LegacyOneBattleverse"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Be among the first to join LegacyOne: Battleverse</h2>
          <div class="info">Leave us your email and you'll be contacted for the beta.</div>
          <form>
            <div class="flex">
              <span>Name</span>
              <input type="text" id="nameInput" />
            </div>

            <div class="flex info">
              <span>Email</span>
              <input type="email" id="emailInput" />
            </div>
          <div>The first 100 people to join will get a special NFT and access to the presale.</div>
            <button class="btn-submit" onClick={handleSubmit}>Submit</button>
            <button class="btn-submit" onClick={closeModal}>Close</button>

          </form>
        </Modal>
      </div>
    </header>
  )
}

export default Hero