import whitepaper from "../assets/whitepaper.pdf"
const menu = [
    {
        text:'About',
        link:'/#about',
    },
    {
        text:'Game Features',
        link:'/#features',
        
    },
    {
      text:'Store',
      link:'/#marketplace',
  },{
    text:'Trailer',
    link:'/#trailer',
},
    {
        text:'Whitepaper',
        link:whitepaper,
        target: '#blank'
    },
    
    ];

      const infoaccordion = [
        {
          title: 'Cross-Game Progression',
          descripcion: 'Immerse yourself in an expansive shooter universe like never before. Our advanced Cross-Game Progression system allows you to carry over skills, achievements, and gear across different shooter titles, offering a continuous and integrated gaming experience.'
        },        
        {
          title:'NFTs for Leveling Up',
          descripcion: 'Achievement has never felt this rewarding! In Legacy One: Battleverse, as you reach certain milestones, you have the opportunity to mint unique NFTs. These aren\'t just digital tokens; they represent special abilities, exclusive characters, powerful weapons, and other in-game assets. Every NFT is a badge of honor, a testament to your skills and achievements in the game.'
        },
        {
          title:'Wallet Integration',
          descripcion: 'To make your journey in Legacy One: Battleverse even more convenient, we offer integrated wallet functionality. This digital wallet is not just a tool; it\'s your personal space to hold and manage the NFTs you earn within the game environment. Safe, secure, and user-friendly, our wallet ensures that your assets are always at your fingertips.'
        },
        {
          title:'Smart Contracts',
          descripcion: 'Safety and legitimacy are our top priorities. With our integrated Smart Contracts, rest assured that every transaction in the game is secure. Whether you\'re buying a new weapon, selling an asset, or trading with other players, our smart contracts ensure that every deal is transparent and legitimate, giving you peace of mind.'
        },
      ];

      
    
      const data = {
        menu,
        infoaccordion,
      };
      
      export default data;