import React from 'react';
import { Headings } from '../../components';
import { data } from '../../constants'
import './Requirements.css';
import YoutubeEmbed from "../../components/EmbedVideo/YoutubeEmbed";


const Requirements = () => {
  return (
    <div className="bg-gradient-black requirements" id="trailer">
      <div className="container section-padding">
        <YoutubeEmbed embedId="QT235eNh7yQ" />

        <div className="row headin">

          <div className="col-12 ">
            <Headings txt="Join the Battleverse!" subtxt="What are you waiting for?" />
          </div>
          <div className="col-md-6 col-12 center">
            <a className="btn btn-custom" href="https://game.legacyone.io/" target="_blank" role="button">Play Now</a>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Requirements