import '../index.css'
import 'bootstrap/dist/css/bootstrap.css';
import { Hero, About, Features, Levels, News, Requirements, Footer } from '../container';

const Home = () => {
  return (
    <>
      <Hero /> 
      <About /> 
      <Features /> 
      <Levels /> 
      <News /> 
      <Requirements /> 
      <Footer />
    </>
  );
};

export default Home;
