import React from 'react';
import { Headings, Carousel } from '../../components';
import './About.css';
import { icon } from '../../assets';



const About = () => {
  return (
    <div id="about" className="bg-gradient-black about">
      <div className="container section-padding">
        <div className="row">
          <div className="col-md-6 col-12">
            <Headings txt="Battleverse" subtxt="Legacy One" />
            <div className="pt-3">
              <div className="bullet">
                <img src={icon} alt="icon" />
                <p className="text-white mt-2">Dive into the future of gaming with Legacy One: Battleverse</p>
              </div>
              <div className="bullet">
                <img src={icon} alt="icon" />
                <p className="text-white mt-2">Engage in thrilling combat, either solo or with friends on a first-person shooter</p>
              </div>
              <div className="bullet">
                <img src={icon} alt="icon" /><p className="text-white mt-2">Exclusive platform designed for PC gamers</p>
              </div>
              <div className="bullet">
                <img src={icon} alt="icon" />
                <p className="text-white mt-2">Revolutionizes gaming with cutting-edge technology giving you a universe of its own</p>
              </div>
              <div className="bullet">
                <img src={icon} alt="icon" /><p className="text-white mt-2">Achievements take on a whole new meaning</p>
              </div>
              <div className="bullet">
              <img src={icon} alt="icon" />
                <p className="text-white mt-2">The future of gaming unfolds with Legacy One: Battleverse</p>
              </div>
              <div className="bullet">
                <img src={icon} alt="icon" /><p className="text-white mt-2">Are you ready to be a part of this gaming legacy?</p>
                <p className="text-white mt-2"></p>
              </div>
            </div>
          </div>
          <div className="col-md-1 col-12"></div>
          <div className="col-md-5 col-12">
            <Carousel />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About