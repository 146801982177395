import '../index.css'
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Menu } from '../components';
import { Footer } from '../container';
import './extra.css'

const PrivacyPolicy = () => {

    return (
        <>
            <Menu />

            <section>
                <h1>Legacy One: Battleverse Privacy Policy</h1>
            </section>
            <section>
                <h2>1. Information We Collect</h2>
                <p>We collect information you provide directly to us, such as when you create an account, upload content, or any other activity that requires user data. We also automatically collect certain information when you use Legacy One: Battleverse, such as IP addresses, device information, and activity on our platform.</p>
            </section>

            <section>
                <h2>2. Use of Information</h2>
                <p>We use the information to enhance the user experience, personalize the platform for you, communicate with you, and ensure the platform's safety and security.</p>
            </section>

            <section>
                <h2>3. Sharing of Information</h2>
                <p>We do not sell or share your personal information with third-party advertisers. We may share information with legal entities when required to comply with legal obligations or to protect Legacy One: Battleverse and its users.</p>
            </section>

            <section>
                <h2>4. Cookies</h2>
                <p>Legacy One: Battleverse uses cookies and similar technologies to improve user experience, monitor platform analytics, and ensure platform functionality.</p>
            </section>

            <section>
                <h2>5. Data Storage & Security</h2>
                <p>We employ security measures to protect your data. However, no platform is completely secure, and we cannot guarantee the absolute security of your data.</p>
            </section>

            <section>
                <h2>6. Third-Party Services</h2>
                <p>We may integrate with third-party services to enhance platform functionality. These third-party services have their own privacy policies, which you should review independently.</p>
            </section>

            <section>
                <h2>7. Changes to Privacy Policy</h2>
                <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated revision date.</p>
            </section>

            <section>
                <h2>8. Contact Us</h2>
                <p>For any questions about this privacy policy, you can contact us at support@legacyone.world</p> 
            </section>


            <Footer />

        </>
    );
};


export default PrivacyPolicy
