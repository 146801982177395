import React from 'react'
import { Headings, Accordions } from '../../components';
import './Levels.css';

const Features = () => {

  return (
    <div className="bg-black" id="features">
      <div className="container section-padding">
        <div className="row">
          <div className="col-md-3 col-12  order-1 order-md-1">
            <Headings txt="Campaign" subtxt="Enjoy online or explore the single player" />
          </div>
          <div className="col-md-9 col-12 order-2 order-md-2">
            <div className="fadeInFromLeft feature-level">
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default Features
