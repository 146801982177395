import React from 'react'
import { Headings } from '../../components';
import './News.css';
import { imastore } from '../../assets';
const News = () => {
  return (
    <div className="bg-black subscribe" id="marketplace">
      <div className="container section-padding">
        <div className="row">
          <div className="col-md-8 col-12 order-2 order-md-1">
            <div className="containerimage">
              <img src={imastore} alt="txt " className="image" />
            </div>
          </div>

          <div className="col-md-4 col-12 order-1 order-md-2">
            <Headings txt="In-Game Marketplace" subtxt="Trade your loot" />
            <p className="text-white">Legacy One: Battleverse introduces an in-game marketplace, a hub where players can buy, sell, or trade their in-game assets. Whether you're on the hunt for a rare weapon, looking to sell an artifact, or trading resources, our marketplace has got you covered. It's not just a feature; it's a thriving economy within the game.</p>
            <div className="d-flex justify-content-center mt-5 mb-5">
              <div className="input-group">
              </div>
            </div>

          </div>



        </div>
      </div>
    </div>
  )
}

export default News