import '../index.css'
import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Menu } from '../components';
import { Footer } from '../container';
import './extra.css'
const Terms = () => {
  return (
    <>
      <Menu />
      <div>
        <section>
          <h1>LegacyOne: Battleverse Terms and Conditions</h1>
        </section>
        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using LegacyOne: Battleverse, you accept and agree to be bound by the terms and provisions of this agreement. If you do not agree to abide by the above, please do not use this service.</p>
        </section>

        <section>
          <h2>2. Use of Service</h2>
          <p>You are solely responsible for any content you post and your use of the service. Illegal or harmful activities are prohibited. We reserve the right to remove content or users who violate this policy.</p>
        </section>

        <section>
          <h2>3. Intellectual Property</h2>
          <p>All original content added by LegacyOne: Battleverse, including but not limited to design, text, graphics, are the intellectual property of LegacyOne: Battleverse, and are protected by appropriate copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display, or transmission of any content on this site is strictly prohibited.</p>
        </section>

        <section>
          <h2>4. User Content</h2>
          <p>By uploading or posting content to LegacyOne: Battleverse, you grant us a non-exclusive, transferable, sub-licensable, royalty-free worldwide license to use, display, and distribute your content. You affirm, represent, and warrant that you own, or have the necessary licenses, rights, consents, to the content you provide.</p>
        </section>

        <section>
          <h2>5. Limitation of Liability</h2>
          <p>In no event shall LegacyOne: Battleverse or its affiliates be liable for any indirect, consequential, special, incidental, or punitive damages, arising out of the use or inability to use the service.</p>
        </section>

        <section>
          <h2>6. Termination</h2>
          <p>LegacyOne: Battleverse reserves the right to terminate your access to the site, without any advance notice.</p>
        </section>

        <section>
          <h2>7. Changes to Terms</h2>
          <p>LegacyOne: Battleverse reserves the right to modify these Terms and Conditions at any time. We do so by posting the updated terms on the site. Your decision to continue to visit and make use of the site after such changes have been made constitutes your formal acceptance of the new Terms and Conditions.</p>
        </section>

        <section>
          <h2>9. Feedback & Complaints</h2>
          <p>Any feedback or complaints can be directed to support@legacyone.world. We strive to resolve any complaints and answer any feedback promptly.</p> 
        </section>


      </div>
      <Footer />

    </>
  );
};


export default Terms
