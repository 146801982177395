import React from 'react';
import './Footer.css';
import { data } from '../../constants';
import logo from '../../assets/logo.png';
import telegramlogo from '../../assets/Telegram_logo.svg.webp';
import xlogo from '../../assets/xlogo.png';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const handleNavigation = (event, path) => {
    event.preventDefault(); // Prevent the default behavior of the <a> tag
    navigate(path);
  };
  return (
    <footer>
      <div className="footer container">

        <div className="row">
          <div className="col-lg-2 col-12 logofooter">
            <img src={logo} alt="logo" />
          </div>
          <div className="col-lg-9 col-12">
    <ul>
        {data.menu.map((item) => (
            <li>
                <a className="nav-link" href={item.link}>{item.text}</a>
            </li>
        ))}
        <li>
            <a href="/PrivacyPolicy" onClick={(e) => handleNavigation(e, '/PrivacyPolicy')}>Privacy Policy</a>
        </li>
        <li>
            <a href="/Terms" onClick={(e) => handleNavigation(e, '/Terms')}>Terms</a>
        </li>
        <li>
            <a href="https://t.me/Legacy_One" target="_blank">
                <img src={telegramlogo} alt="Telegram" class="medialogo"/>
            </a>
        </li>
        <li>
            <a href="https://x.com/LegacyOneBV?t=8lDr8_Qpp-HxHyojvWy7vg&s=35" target="_blank" >
                <img src={xlogo} alt="Twitter"  class="medialogo"/>
            </a>
        </li>
    </ul>
</div>

          <div className="col-lg-5 col-12 icons">
            {/* <FaYoutube /> <FaFacebookF /> <FaTwitch /> <FaInstagram /> */}
            {/* <a href="privacypolicy.html">Privacy</a>
                <span class="mx-1">&middot;</span>
                <a href="terms.html">Terms</a> */}
          </div>

        </div>
      </div>
      <div className="container">
        <div className="rights">
          {/* <p classsName="text-right">Privacy Policy | Terms of Services | Code of Conduct </p> */}
        </div>

      </div>

    </footer>
  )
}

export default Footer
