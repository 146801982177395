import './index.css'
import 'bootstrap/dist/css/bootstrap.css';
import {   Hero, About, Features, Requirements, News , Footer, Levels } from './container';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Terms from './pages/terms';  // Adjust the path if needed
import PrivacyPolicy from './pages/privacypolicy'; // Adjust the path if needed
import Home from './pages/home'; // Your existing main screen component
function App() {


  // return (
  //   <>
  //    <Hero /> 
  //    <About /> 
  //    <Features /> 
  //    <Levels /> 

  //    <News /> 
     
  //    <Requirements /> 
  //    <Footer />
  //   </>
  // )
  return (
    <Router>
        <div>
            {/* Route definitions */}
            <Routes>
            <Route path="/" element={<Home />} />
                <Route path="/Terms" element={<Terms />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />}  />
            </Routes>
        </div>
    </Router>
);
}

export default App
